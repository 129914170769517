//画面サイズごとの処理
$(window).on("load resize", function () {
  const w = $(window).width();
  deviceFunc(w);
});

const browser = browserCheck();
const os = osCheck();
const device = deviceCheck();
//$("body").addClass(device).addClass(browser).addClass(os);

$(document).ready(function () {
  var state = false;
  var pos;
  //ハンバーガーメニュー表示
  $("#menu-btn").on("click", function () {
    $(".header__tbmenu").toggleClass("is-open");
    if (state == false) {
      //pos = $(window).scrollTop();
      //$('body').addClass('fixed').css({ 'top': -pos });
      //$(".header__nav--listitem a img").show();
      $("body").addClass("is-fixed");
      state = true;
    } else {
      //$(".header__nav--listitem a img").hide();
      $("body").removeClass("is-fixed");
      //$('body').removeClass('fixed').css({ 'top': 0 });
      //window.scrollTo(0, pos);
      state = false;
    }
  });

  // topへのボタン非表示
  var pagetop = $("#page_top");
  pagetop.hide();

  // 100px スクロールしたらtopへのボタン表示
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      pagetop.fadeIn();
    } else {
      pagetop.fadeOut();
    }
  });
  pagetop.click(function () {
    $("body, html").animate({ scrollTop: 0 }, 500);
    return false;
  });

  // swiper
  var bar = document.querySelector(".progressbar_in");
  var speed = 8000;
  var slideData = [
    {
      title:
        "<span>書籍のご案内</span><br>アクチュアリー試験 合格へのストラテジー <br>年金2次 DB編（上）2022年版　発売中",
      text: '<a href="./publication/" class="link-keyvisual">詳しくはこちら</a>',
    }, //スライド2番目の内容
    {
      title: "当社は企業が抱える退職給付制度の課題を解決する会社です",
      text: '<a href="./service/" class="link-keyvisual">詳しくはこちら</a>',
    }, //スライド1番目の内容
  ];
  const swiper = new Swiper(".swiper", {
    // Optional parameters
    loop: true,
    speed: 1000,
    autoplay: {
      delay: speed,
      disableOnInteraction: false,
    },
    on: {
      slideChangeTransitionStart: function () {
        (bar.style.transitionDuration = "0s"),
          (bar.style.transform = "scaleX(0)");
      },
      slideChangeTransitionEnd: function () {
        (bar.style.transitionDuration = speed + "ms"),
          (bar.style.transform = "scaleX(1)");
      },
      slideChange: function () {
        var currentSlideIndex = this.activeIndex % slideData.length; // スライドのインデックスを取得
        $(".keyvisual__swipertextarea--title h1").html(
          slideData[currentSlideIndex].title
        );
        $(".keyvisual__swipertextarea--text p").html(
          slideData[currentSlideIndex].text
        );/*
        // 一つ目のスライドの場合にwideクラスを追加
        if (currentSlideIndex === 1) {
          $(".keyvisual__swipertextarea").addClass("wide");
        }else{
          $(".keyvisual__swipertextarea").removeClass("wide");
        }*/
      },
    },
    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
      paginationClickable: true,
    },

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    // And if we need scrollbar
    scrollbar: {
      el: ".swiper-scrollbar",
      draggable: true,
    },
  });

  //タブ制御（トップページインフォメーション）
  $(".information__tabmenu--item").click(function () {
    //現在activeが付いているクラスからactiveを外す
    $(".active-tb").removeClass("active-tb");
    //クリックされたタブメニューにactiveクラスを付与
    $(this).addClass("active-tb");
    //一旦showクラスを外す
    $(".show").removeClass("show");
    //クリックしたタブのインデックス番号取得
    const index = $(this).index();
    //タブのインデックス番号と同じコンテンツにshowクラスをつけて表示する
    $(".information__tabcontent").eq(index).addClass("show");
  });

  $('select[name="tabmenu"]').change(function () {
    //一旦showクラスを外す
    $(".show").removeClass("show");
    //クリックしたタブのインデックス番号取得
    const index = $('select[name="tabmenu"] option:selected').attr("value");
    //タブのインデックス番号と同じコンテンツにshowクラスをつけて表示する
    $(".information__tabcontent").eq(index).addClass("show");
  });

  //リンク先のidまでスムーススクロール
  //※ページ内リンクを行わない場合は不必要なので削除してください
  /*
  var headerH = $("header").outerHeight(true); //headerの高さを取得
  $(".header__nav--listitemlink").click(function () {
    var elmHash = $(this).attr("href");
    var pos = $(elmHash).offset().top - headerH; //header分の高さを引いた高さまでスクロール
    $("body,html").animate({ scrollTop: pos }, 1000);
    return false;
  });*/

  // ページ内スクロール（用語集）
  $('a[href*="#"]').click(function () {
    const speed = 600;
    let href = $(this).attr("href");
    let target = $(href == "#" || href == "" ? "html" : href);
    let position = 0;
    var windowWidth = $(window).width();
    var deviceWidth = 959;
    if (windowWidth <= deviceWidth) {
      //959px以下の時の処理
      position = target.offset().top - 60; //位置調整
    } else {
      //960pxより大きい時の処理
      position = target.offset().top - 100;
    }
    $("body,html").animate({ scrollTop: position }, speed, "swing");
    return false;
  });

  //アコーディオンをクリックした時の動作（用語集）
  $(".accordion__listitem--title").on("click", function () {
    //タイトル要素をクリックしたら
    var findElm = $(this).next(".box"); //直後のアコーディオンを行うエリアを取得し
    $(findElm).slideToggle(); //アコーディオンの上下動作

    if ($(this).hasClass("close")) {
      //タイトル要素にクラス名closeがあれば
      $(this).removeClass("close"); //クラス名を除去し
    } else {
      //それ以外は
      $(this).addClass("close"); //クラス名closeを付与
    }
  });

  //モーダル（年金退職金レタースマホ）
  if ($(".mordal").length) {
    let dialog_theme = document.getElementById("dialog-theme");
    let dialog_author = document.getElementById("dialog-author");
    let open_theme = document.getElementById("open-theme");
    let open_author = document.getElementById("open-author");
    let close_theme = document.getElementById("close-theme");
    let close_author = document.getElementById("close-author");
    open_theme.addEventListener(
      "click",
      function () {
        dialog_theme.showModal();
        $("html").css("overflow", "hidden");
      },
      false
    );
    open_author.addEventListener(
      "click",
      function () {
        dialog_author.showModal();
        $("html").css("overflow", "hidden");
      },
      false
    );
    close_theme.addEventListener(
      "click",
      function () {
        dialog_theme.close();
        $("html").css("overflow", "auto");
      },
      false
    );
    close_author.addEventListener(
      "click",
      function () {
        dialog_author.close();
        $("html").css("overflow", "auto");
      },
      false
    );
  }

  //アンカーリンク位置変更（トップインフォメーション→セミナー遷移）
  // ページのURLを取得
  const url = $(location).attr("href");
  // headerの高さを取得してそれに30px追加した値をheaderHeightに代入
  headerHeight = $("header").outerHeight() + 30;

  // urlに「#」が含まれていれば
  if (url.indexOf("#") != -1) {
    // urlを#で分割して配列に格納
    const anchor = url.split("#");
    // 分割した最後の文字列（#◯◯の部分）をtargetに代入
    target = $("#" + anchor[anchor.length - 1]);
    // リンク先の位置からheaderHeightの高さを引いた値をpositionに代入
    position = Math.floor(target.offset().top) - headerHeight;
    // positionの位置に移動
    $("html, body").animate({ scrollTop: position }, 500);
  }

  //画面幅767px以下の時、tableは横スクロールさせる（年金・退職金レターのみ有効）
  if ($("body#letter").length) {
    $("table").wrap('<div class="table-scroll"></div>');
  }
  //インフォメーション（記事）で作成したtableは横スクロールさせる
  if ($("#information").length) {
    $("table").wrap('<div class="table-scroll"></div>');
  }

  //Cookieの承諾
  const expire = 365; // 有効期限（日）
  const delay = 1000; // 承諾エリアがでてくるまでのミリ秒
  const flagName = "cookieAgreeJP";
  let cc = document.querySelector(".cookie-consent");
  let ca = document.querySelector(".cookie-agree");
  const flag = localStorage.getItem(flagName);
  if (flag != null) {
    const data = JSON.parse(flag);
    if (data["value"] == "true") {
      setTimeout(popup, delay);
    } else {
      const current = new Date();
      if (current.getTime() > data["expire"]) {
        setWithExpiry(flagName, "true", expire);
        setTimeout(popup, delay);
      }
    }
  } else {
    setWithExpiry(flagName, "true", expire);
    setTimeout(popup, delay);
  }
  ca.addEventListener("click", () => {
    cc.classList.add("hide");
    setWithExpiry(flagName, "false", expire);
  });

  function setWithExpiry(key, value, expire) {
    const current = new Date();
    expire = current.getTime() + expire * 24 * 3600 * 1000;
    const item = {
      value: value,
      expire: expire,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  function popup() {
    cc.classList.add("is-show");
  }
});

function deviceFunc(w) {
  const bp = [559, 959, 1280];
  if (w <= bp[0]) {
    //sp
    $(".tel-number").each(function () {
      if (!$(this).parent(".tel-wrap").length) {
        const no = $(this).data("tel");
        $(this).wrap('<a href="tel:' + no + '" class="tel-wrap"></a>');
      }
    });
  } else if (w <= bp[1]) {
    //tb
    $(".tel-wrap .tel-number").each(function () {
      $(this).unwrap();
    });
  } else if (w <= bp[2]) {
    //small pc
  } else {
  }
}
/*ブラウザ判別*/
function browserCheck() {
  const ua = window.navigator.userAgent.toLowerCase();
  const av = window.navigator.appVersion.toLowerCase();
  if (ua.indexOf("edge") != -1) {
    return "edge";
  } else if (ua.indexOf("opera") != -1) {
    return "opera";
  } else if (ua.indexOf("msie") != -1 || ua.indexOf("trident") != -1) {
    $("body").addClass("ie");
    if (av.indexOf("msie 6.") != -1) {
      return "ie6";
    } else if (av.indexOf("msie 7.") != -1) {
      return "ie7";
    } else if (av.indexOf("msie 8.") != -1) {
      return "ie8";
    } else if (av.indexOf("msie 9.") != -1) {
      return "ie9";
    } else if (av.indexOf("msie 10.") != -1) {
      return "ie10";
    } else if (av.indexOf("rv:11.") != -1) {
      return "ie11";
    }
  } else if (ua.indexOf("chrome") != -1) {
    return "chrome";
  } else if (ua.indexOf("safari") != -1) {
    return "safari";
  } else if (ua.indexOf("gecko") != -1) {
    return "firefox";
  } else {
    return false;
  }
}

/*OS判別*/
function osCheck() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf("windows") != -1) {
    if (ua.indexOf("phone") != -1) {
      return "winphone";
    } else {
      return "win";
    }
  } else if (ua.indexOf("iphone") != -1) {
    return "iphone";
  } else if (ua.indexOf("ipad") != -1) {
    return "ipad";
  } else if (ua.indexOf("mac") != -1) {
    return "mac";
  } else if (ua.indexOf("android") != -1) {
    if (ua.indexOf("mobile") != -1) {
      if (ua.indexOf("A1_07") != -1) {
        return "tablet";
      } else if (ua.indexOf("SC-01C") != -1) {
        return "tablet";
      } else {
        return "android";
      }
    } else {
      return "tablet";
    }
  } else {
    return false;
  }
}

/*デバイス判別*/
function deviceCheck() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (
    (ua.indexOf("windows") != -1 &&
      ua.indexOf("touch") != -1 &&
      ua.indexOf("tablet pc") == -1) ||
    ua.indexOf("ipad") != -1 ||
    (ua.indexOf("android") != -1 && ua.indexOf("mobile") == -1) ||
    (ua.indexOf("firefox") != -1 && ua.indexOf("tablet") != -1) ||
    ua.indexOf("kindle") != -1 ||
    ua.indexOf("silk") != -1 ||
    ua.indexOf("playbook") != -1
  ) {
    return "tab";
  } else if (
    (ua.indexOf("windows") != -1 && ua.indexOf("phone") != -1) ||
    ua.indexOf("iphone") != -1 ||
    ua.indexOf("ipod") != -1 ||
    (ua.indexOf("android") != -1 && ua.indexOf("mobile") != -1) ||
    (ua.indexOf("firefox") != -1 && ua.indexOf("mobile") != -1) ||
    ua.indexOf("blackberry") != -1
  ) {
    return "sp";
  } else {
    return "pc";
  }
}
